<template>
  <div class="global-box penlin">
    <el-form :inline="true" :model="form" class="global-form global-form-inline">
      <el-form-item label="区域">
        <el-select v-model="form.houseid" placeholder="">
          <el-option label="全部" value=""></el-option>
          <el-option label="一区" value=""></el-option>
          <el-option label="二区" value=""></el-option>

        </el-select>
      </el-form-item>

      <el-form-item label="识别码">
        <el-input v-model="form.employee" placeholder="" />
      </el-form-item>

      <el-form-item>
        <span class="btn-reset" @click="onReset" style="margin-right: 10px">重置</span>
        <span class="btn-search" @click="onSubmit">查询</span>
      </el-form-item>
    </el-form>


    <div class="env">
      <div class="title">环控器</div>
      <div class="box">
        <div class="env-card">
          <span class="status">运行中</span>
          <div class="area">1区
          </div>
          <div>
            <span class="name">环控器</span>
            <span class="code">识别码：129380</span>
          </div>
          <div class="numbers">
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">控制单元</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">正常</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">摄像头异常</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">停用</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">离线</span>
            </div>
          </div>
        </div>
        <div class="env-card">
          <span class="status">运行中</span>
          <div class="area">2区
          </div>
          <div>
            <span class="name">环控器</span>
            <span class="code">识别码：129380</span>
          </div>
          <div class="numbers">
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">控制单元</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">正常</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">摄像头异常</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">停用</span>
            </div>
            <div class="num-item">
              <span class="num">8</span>
              <span class="text">离线</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="unit">
      <div class="title">控制单元</div>
      <div class="con_box">

        <div class="unit_item" v-for="(u, index) in unitList" :key="u.id">
          <div class="u_title">
            <span>一区</span>
            <span class="status">运行中</span>
          </div>
          <div>控制单元</div>
          <div>识别码：129380</div>
          <div class="top_border shexiang">摄像头1号
            <span class="dot"></span>
          </div>
          <div class="top_border shexiang">摄像头2号
             <span class="dot"></span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

const unitList = Array.from({ length: 16 }, (v, k) => {
  return {
    id: k
  }
})
export default {
  data() {
    return {
      form: {},
      unitList,
    }
  },
  methods: {

  }
}
</script>
<style scoped lang="less" src="./style.less"></style>